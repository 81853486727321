import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
    border: '1px solid #E8ECF0',
    boxShadow: '0px 2px 32px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.04)'
  },
  title: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#29333D'
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#29333D'
  },
  infoText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#29333D',
    textAlign: 'center'
  },
  contactUsBtn: {
    whiteSpace: 'nowrap',
    borderRadius: '6px',
    border: '1px solid #E8ECF0',
    color: '#29333D',
    outline: 'none',
    fontWeight: 500,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '&:hover': {
      border: '1px solid transparent'
    }
  }
}))

import {isValidPhoneNumber} from 'libphonenumber-js'
import {z} from 'zod'

import {InternalErrors} from '../../../ErrorMap'
import {handleAllowedCharacters} from '../../../utils'
import {
  RequestAccessRoles,
  RequestAccessSelfOnboardingFormData
} from '../RequestAccessSelfOnboarding.types'

export const RequestAccessSchema: z.ZodType<RequestAccessSelfOnboardingFormData> = z
  .object({
    fullName: z.string().trim().nonempty(InternalErrors.FIELD_REQUIRED),
    email: z
      .string()
      .trim()
      .optional()
      .refine((value) => !value || (value?.includes('@') && handleAllowedCharacters(value))),
    mobileNumber: z
      .string()
      .trim()
      .optional()
      .refine(
        (value) => !value || (value && isValidPhoneNumber(value)),
        InternalErrors.INVALID_MOBILE_NUMBER
      ),
    requestedRoleType: z.nativeEnum(RequestAccessRoles, {message: InternalErrors.FIELD_REQUIRED}),
    companyName: z.string().trim().nonempty(InternalErrors.FIELD_REQUIRED),
    market: z.string().trim().nonempty(InternalErrors.FIELD_REQUIRED),
    countryId: z.string().trim().nonempty(InternalErrors.FIELD_REQUIRED),
    customerIds: z
      .array(z.object({id: z.string().trim().nonempty(InternalErrors.FIELD_REQUIRED)}))
      .min(1, InternalErrors.FIELD_REQUIRED)
  })
  .superRefine((data, ctx) => {
    if (!data.email && !data.mobileNumber) {
      ctx.addIssue({
        path: ['email'],
        message: InternalErrors.PHONE_OR_EMAIL_REQUIRED,
        code: z.ZodIssueCode.custom
      })
      ctx.addIssue({
        path: ['mobileNumber'],
        message: InternalErrors.PHONE_OR_EMAIL_REQUIRED,
        code: z.ZodIssueCode.custom
      })
    }
  })

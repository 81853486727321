import {useBreakPoints} from '@hconnect/uikit'
import {InputMessage} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Button, Card, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {LinkToSignIn} from '../../../../Components/AccountForm/LinkToSignIn'
import {StepForm} from '../../../../Components/SelfOnBoarding/StepForm'
import {useRafStyles} from '../../styles'

import {useStyles} from './RegistrationStep.styles'

import {ReactComponent as RegistrationCustomerNumber} from '@hconnect/authenticator/src/Assets/raf_registration_customer_number.svg'
import {ReactComponent as RegistrationInvoice} from '@hconnect/authenticator/src/Assets/raf_registration_invoice.svg'

const contactUsUrl =
  'https://forms.office.com/pages/responsepage.aspx?id=BiSVVyivyEO03qTgb1dHbXPkKU6CsC5MsqIt5o24w4hUMzFHNjYwSVhYQTBVNFJMWEdKRlNRQjJSOCQlQCN0PWcu&route=shorturl'

export const RegistrationStep = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs'].includes(screenSizes)
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {classes: generalClasses} = useRafStyles()
  const handleContactUsLink = () => {
    window.open(contactUsUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <Box style={{display: 'flex', flexDirection: 'column'}}>
      <Typography className={classes.title}>
        {t('authenticator.requestAccessSelfOnboarding.registrationStep.title')}
      </Typography>
      <Typography
        className={classes.subTitle}
        style={{
          marginTop: '16px'
        }}
      >
        {t('authenticator.requestAccessSelfOnboarding.registrationStep.subtitle')}
      </Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '16px',
          marginTop: '32px',
          marginBottom: '10px'
        }}
      >
        <Card className={classes.infoCard}>
          <RegistrationCustomerNumber />
          <Typography className={classes.infoText}>
            {t('authenticator.requestAccessSelfOnboarding.registrationStep.customerNumberInfo')}
          </Typography>
        </Card>
        <Card className={classes.infoCard}>
          <RegistrationInvoice />
          <Typography className={classes.infoText}>
            {t('authenticator.requestAccessSelfOnboarding.registrationStep.invoiceInfo')}
          </Typography>
        </Card>
      </Box>
      <InputMessage
        variant="info"
        startIcon={<InfoOutlined style={{fontSize: '24px'}} />}
        message={t(
          'authenticator.requestAccessSelfOnboarding.registrationStep.contactUsDescription'
        )}
        actionItem={
          <Button
            className={classes.contactUsBtn}
            onClick={handleContactUsLink}
            data-test-id="contact-us-button"
          >
            {t('authenticator.requestAccessSelfOnboarding.registrationStep.contactUsTitle')}
          </Button>
        }
      />
      <StepForm
        submitText={t(
          'authenticator.requestAccessSelfOnboarding.registrationStep.startRegistration'
        )}
        data-test-id="start-registration-btn"
        className={generalClasses.confirmButton}
      />
      <Box style={{placeSelf: 'center'}}>
        <LinkToSignIn />
      </Box>
    </Box>
  )
}

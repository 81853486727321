import {publicApi} from '@hconnect/adminconsole/src/App.store'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useHistory} from 'react-router-dom'

import {routes} from '../../../routes'

export type RequestAccessPayload = {
  fullName: string
  requestedRoleType: string
  companyName: string
  market: string
  countryId: string
  customerIds: string[]
  isTermsApprovalRequired: boolean
  termsAcceptedOn: string
  creationChannel: string | null
  creationProduct: string | null
  termsAcceptedVersion: string | null
  email?: string
  mobileNumber?: string
  defaultLocale?: string
}
const createAccessRequest = async (data: RequestAccessPayload) => {
  return await publicApi.post('/requests', data)
}
export const useRequestAccess = () => {
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(createAccessRequest, {
    onSuccess: (data) => {
      if (data.status === 201) {
        history.push(routes.RequestAccessSuccess)
      }
    },
    onError: (e) => {
      const error = e as AxiosError
      enqueueSnackbar(error.message, {variant: 'error'})
    }
  })
}

export type RequestAccessSelfOnboardingFormData = {
  fullName: string
  requestedRoleType: RequestAccessRoles
  companyName: string
  market: string
  countryId: string
  customerIds: Customer[]
  email?: string
  mobileNumber?: string
}

type Customer = {
  id: string
}

export enum RequestAccessRoles {
  ORDER_PLACER = 'ORDER_PLACER',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  SALES_AGENT = 'SALES_AGENT'
}

import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#29333D'
  }
}))

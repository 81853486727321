import {makeStyles} from 'tss-react/mui'

export const useRafStyles = makeStyles()((theme) => ({
  confirmButton: {
    width: 'auto',
    placeSelf: 'center',
    marginTop: '24px',
    marginBottom: '12px',
    fontWeight: 500,
    backgroundColor: '#016AD4',
    color: '#FFFFFF',
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '& .MuiButtonBase-root': {
        width: '100%'
      }
    },
    '&:hover': {
      backgroundColor: '#0162C4'
    }
  },
  secondaryButton: {
    whiteSpace: 'nowrap',
    padding: 0,
    borderRadius: '6px',
    border: '1px solid #E8ECF0',
    color: '#29333D',
    outline: 'none',
    fontWeight: 500,
    backgroundColor: '#FFFFFF',
    '&:hover': {
      border: '1px solid transparent'
    }
  }
}))

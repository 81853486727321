import {CreationChannel, CreationProduct, Product} from '@hconnect/apiclient'
import {zodResolver} from '@hookform/resolvers/zod'
import {useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'

import {useAvailableCountries} from '../../Components/AccountForm/useCountries'
import {useGlobalState} from '../../hooks/useGlobalState'
import {calcDefaultLocale, getBrowserLanguage} from '../../utils'

import {FormStepper} from './components/FormStepper'
import {RequestAccessPayload, useRequestAccess} from './hooks/useRequestAccess'
import {RequestAccessSelfOnboardingFormData} from './RequestAccessSelfOnboarding.types'
import {RequestAccessSchema} from './validators/RequestAccessSchema'

export const RequestAccessSelfOnboarding = () => {
  const {globalState} = useGlobalState()
  const availableCountries = useAvailableCountries()
  const requestAccessMutation = useRequestAccess()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [termsVersion, _] = useState<string | null>(null)
  const formMethods = useForm<RequestAccessSelfOnboardingFormData>({
    mode: 'onChange',
    resolver: zodResolver(RequestAccessSchema),
    defaultValues: {
      customerIds: [{id: ''}]
    }
  })

  const onSubmit = (data: RequestAccessSelfOnboardingFormData) => {
    const preferredLanguage = !globalState.lng ? getBrowserLanguage() : globalState.lng
    const selectedCountry = availableCountries?.find(
      (country) => country.countryCode === data.countryId
    )
    const defaultLocale = selectedCountry && calcDefaultLocale(selectedCountry, preferredLanguage)
    const body: RequestAccessPayload = {
      fullName: data.fullName,
      email: data.email,
      mobileNumber: data.mobileNumber,
      requestedRoleType: data.requestedRoleType,
      companyName: data.companyName,
      market: data.market,
      countryId: data.countryId,
      customerIds: data.customerIds.map((customer) => customer.id),
      isTermsApprovalRequired: globalState.clientId === Product.OnSite,
      creationChannel: 'inAuthRegistration' as CreationChannel,
      creationProduct: globalState.clientId as CreationProduct,
      defaultLocale: defaultLocale,
      termsAcceptedOn: new Date().toISOString(),
      termsAcceptedVersion: termsVersion
    }

    requestAccessMutation.mutate(body)
  }

  const handleSubmit = () => {
    console.log('submitted')
    formMethods.handleSubmit(onSubmit)
  }

  return (
    <FormProvider
      {...formMethods}
      handleSubmit={() => formMethods.handleSubmit((data) => onSubmit(data))}
    >
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        data-test-id="request-access-self-onboarding-form"
        style={{height: '100%'}}
      >
        <FormStepper onSubmit={handleSubmit} />
      </form>
    </FormProvider>
  )
}
